<template>
  <footer class="py-4 bg-light mt-auto">
            <div class="container-fluid px-4">
              <div class="d-flex align-items-center justify-content-between small">
                <div class="text-muted">Copyright &copy; fixtureando.com.ar 2024</div>
                <div>
                  <a href="#">Privacy Policy</a>
                  &middot;
                  <a href="#">Terminos y condiciones &amp; </a>
                </div>
              </div>
            </div>
          </footer>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'FooterAdmin',
    props: {
      msg: String
    }
  });
  </script>


