import { UsuarioAdmin } from "./UsuarioAdmin";

/**
 * @description Clase base utilizada por otras clases del sistema para gestionar información de auditoría.
 * Esta clase provee propiedades comunes para registrar la fecha de alta, baja, usuarios 
 * relacionados a estas acciones y referencias a objetos UsuarioAdmin para un contexto más 
 * amplio.
 */
export class AuditableEntity {
  /**
   * @description Fecha de alta de la entidad. Se almacena como un objeto Date o null si la entidad aún no ha sido creada.
   */
  fechaAlta: Date | null;

  /**
   * @description Fecha de baja de la entidad. Se almacena como un objeto Date o null si la entidad no ha sido dada de baja.
   */
  fechaBaja: Date | null;

  /**
   * @description Identificador del usuario que creó la entidad. Se almacena como un número o null si no se tiene información al respecto.
   */
  IdUsuarioAlta: number | null;

  /**
   * @description Identificador del usuario que modificó la entidad por última vez. Se almacena como un número o null si no se tiene información al respecto.
   */
  IdUsuarioModificacion: number | null;

  /**
   * @description Identificador del usuario que dio de baja la entidad. Se almacena como un número o null si no se tiene información al respecto.
   */
  IdUsuarioBaja: number | null;

  /**
   * @description Objeto de tipo UsuarioAdmin que representa al usuario que creó la entidad. Se puede utilizar para obtener más información sobre el usuario, como su nombre o correo electrónico.
   */
  usuarioAlta?: UsuarioAdmin | null;

  /**
   * @description Objeto de tipo UsuarioAdmin que representa al usuario que modificó la entidad por última vez. Se puede utilizar para obtener más información sobre el usuario, como su nombre o correo electrónico.
   */
  usuarioModificacion?: UsuarioAdmin | null;

  /**
   * @description Objeto de tipo UsuarioAdmin que representa al usuario que dio de baja la entidad. Se puede utilizar para obtener más información sobre el usuario, como su nombre o correo electrónico.
   */
  usuarioBaja?: UsuarioAdmin | null;

  /**
   * @description Constructor de la clase AuditableEntity. Inicializa las propiedades con valores null.
   */
  constructor() {
    this.fechaAlta = null;
    this.fechaBaja = null;
    this.IdUsuarioAlta = null;
    this.IdUsuarioModificacion = null;
    this.IdUsuarioBaja = null;
    this.usuarioAlta = null;
    this.usuarioModificacion = null;
    this.usuarioBaja = null;
  }
}
