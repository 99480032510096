<template>
  <div class="container mt-4">
    <h1 class="text-primary">Editar Fixture</h1>
    <div v-if="fixture">
      <form @submit.prevent="guardarCambios">
        <div class="row mb-3">
          <div class="col">
            <label for="fechaInicio" class="form-label">Fecha Inicio</label>
            <input type="date" id="fechaInicio" v-model="fixture.fechaInicio" class="form-control">
          </div>
          <div class="col">
            <label for="fechaFin" class="form-label">Fecha Fin</label>
            <input type="date" id="fechaFin" v-model="fixture.fechaFin" class="form-control">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="nombre" class="form-label">Nombre</label>
            <input type="text" id="nombre" v-model="fixture.nombre" class="form-control">
          </div>
          <div class="col">
            <label for="valorPorParticipante" class="form-label">Valor por Participante</label>
            <input type="number" id="valorPorParticipante" v-model="fixture.valorPorParticipante" class="form-control">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="tipoDeFixtureId" class="form-label">Tipo de Fixture</label>
            <input type="text" id="tipoDeFixtureId" v-model="fixture.tipoDeFixtureId" class="form-control" readonly>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">Guardar</button>
      </form>
    </div>
    <div v-else>
      <p>Cargando...</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { Fixture } from '@/interfaces/Fixture';
import FixturesService from '@/services/FixturesService';

import moment from 'moment';

const route = useRoute();
const fixtureId = ref<number | null>(null);




var fixture = ref<Fixture | null>(null);
const formattedFechaInicio = ref('');

const fetchFixture = async () => {
  if (route.params && route.params.id) {
    fixtureId.value = parseInt(route.params.id as string, 10);
    try {
      const response = await FixturesService.getFixtureById(fixtureId.value);
      if (response.isSuccess) {
        fixture.value = response.result;
        formattedFechaInicio.value = moment(fixture.value!.fechaInicio).format('YYYY-MM-DD');
      } else {
        console.error('Error fetching fixture:', response.errores);
      }
    } catch (error) {
      console.error('Error fetching fixture:', error);
    }
  } else {
    console.error('No se pudo obtener el id del fixture.');
  }
  
};

onMounted(fetchFixture);

const formatFecha = (fecha: Date): string => moment(fecha).format('DD/MM/YYYY');

const guardarCambios = async () => {
  if (fixture.value) {
    fixture.value.fechaInicio = new Date(formattedFechaInicio.value);
    try {
      const response = await FixturesService.updateFixture(fixture.value);
      if (response.isSuccess) {
        alert('Fixture actualizado con éxito');
        // Redirigir al listado o realizar otra acción según sea necesario, por ejemplo mostrar un toast con el mensaje
      } else {
        console.error('Error updating fixture:', response.errores);
      }
    } catch (error) {
      console.error('Error updating fixture:', error);
    }
  }
};

</script>

<style scoped></style>
