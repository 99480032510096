/**
   * @description Ejemplo de una ruta y su explicaciom
   * { 
      path: '/fixtures/:id', // Ruta para editar o crear un fixture, el ':id' es un parámetro dinámico
      name: 'NuevoEditarFixture', // Nombre de la ruta, útil para navegación programática
      component: NuevoEditarFixturePage, // Componente que se cargará cuando se visite esta ruta
      props: true, // Permite que el parámetro 'id' se pase como prop al componente
    },  
    Si no se especifica ningua ruta se redirije al panel (podria ser al login tambien)
    { path: '/', redirect: 'Panel' },//


   */

import { createRouter, createWebHistory, RouteRecordInfo, RouteRecordRaw } from 'vue-router';
import PanelPage from '@/pages/panel/Panel.vue';
import FixturesPage from '@/pages/fixtures/FixturesPage.vue';
import NuevoEditarFixturePage from '@/pages/fixtures/NuevoEditarFixturePage.vue';

const routes:Array<RouteRecordRaw> = [  
  { path: '/', redirect: 'Panel' },//
  { path: '/panel', name: 'Panel', component: PanelPage },
  { path: '/fixtures', name: 'Fixtures', component: FixturesPage },
  {
    path: '/fixtures/:id',
    name: 'NuevoEditarFixture',
    component: NuevoEditarFixturePage,
    props: true,
  },  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;