<template>
 
    <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div class="sb-sidenav-menu">
              <div class="nav">
                 <div class="sb-sidenav-menu-heading">Admin</div> 
                <router-link class="nav-link" :to="{name:'Panel'}">
                  <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                  Panel
                </router-link>
                <!-- <div class="sb-sidenav-menu-heading">Interface</div> -->
                            <!-- <a class="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                                Configuraciónes
                                <div class="sb-sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                            </a>
                            <div class="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav class="sb-sidenav-menu-nested nav">
                                    <a class="nav-link" href="layout-static.html">Tipos Torneo</a>
                                    <a class="nav-link" href="layout-sidenav-light.html">Tipos Partidos</a>
                                </nav>
                            </div> -->
                  
                  <!-- En las rutas se debe usar :to="{name:'Fixtures'}" y no  to="/fixtures", ya que de la forma con :to depende del nombre y no de una ruta y es mas escalable, si tengo que cambiar el valor de la ruta deberia cambiarlo en todos  -->
                  <router-link class="nav-link" :to="{name:'Fixtures'}">
                    <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                    Fixtures
                </router-link>
                <router-link class="nav-link" to="/participantes">
                  <div class="sb-nav-link-icon"><i class="fas fa-chart-area"></i></div>
                  Participantes
                </router-link>



                 <!-- <router-link class="nav-link" to="/tables">
                  <div class="sb-nav-link-icon"><i class="fas fa-table"></i></div>
                  Tipos
                </router-link>  -->
              </div>
            </div>
            <div class="sb-sidenav-footer">
              <div class="small">Logeado como:</div>
              Admin
            </div>
          </nav>
    
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'NavBarLeft'    
  });
  </script>


<style lang="css" scoped></style>