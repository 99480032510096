import { AuditableEntity } from './AuditableEntity';
import { UsuarioAdmin } from './UsuarioAdmin';
import { TipoDeFixture } from './TipoDeFixture';

export class Fixture extends AuditableEntity {
  id: number;
  nombre: string;
  fechaInicio: Date;
  fechaFin: Date;
  valorPorParticipante: number;
  tipoDeFixtureId: number;
  tipoDeFixture: TipoDeFixture | null = null;
  

  constructor(
    id: number,
    nombre: string,
    fechaInicio: Date,
    fechaFin: Date,
    valorPorParticipante: number,
    tipoDeFixtureId: number,
    tipoDeFixture: TipoDeFixture 
  ) {
    super();
    this.id = id;
    this.nombre = nombre;
    this.fechaInicio = fechaInicio;
    this.fechaFin = fechaFin;
    this.valorPorParticipante = valorPorParticipante;
    this.tipoDeFixtureId = tipoDeFixtureId;
    this.tipoDeFixture = tipoDeFixture;
  }
}
