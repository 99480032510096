<template>
    <LayoutPrincipal />
</template>
<script lang="ts">

/**
   * @description El main.ts  cargara este componente, el cual mostrara el componente LayoutPrincipal el cual contiene el navbar, el footer etc separados por componentes
   * En desarrollo futuro este componente quiza cargue el componente que sera la pagina del login y desde el login se redirijira a una pagina que tenga este LayoutPrincipal
   */

import './assets/css/styles.css';
import 'bootstrap';
import "./assets/js/scripts.js";   
import { defineComponent } from 'vue';

import LayoutPrincipal from '@/components/LayoutPrincipal.vue'; 
export default defineComponent({
  name: 'App',
  components: {
    LayoutPrincipal,
  },
});
</script>
<style>
/* Estilos específicos para el enlace activo y exacto. Cuando se selecciona un elemento del menu izquierdo, al seleccionado se le agrega esta clase, la cual, su texto es verde para indicar que esta seleccionada*/
.router-link-active {
  color: green !important; /* Color del texto con !important */
  font-weight: bold !important; /* Negrita con !important */
}

.router-link-exact-active {
  color: green !important; /* Color del texto con !important */
  font-weight: bold !important; /* Negrita con !important */
}
</style>
