import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sb-nav-fixed" }
const _hoisted_2 = { id: "layoutSidenav" }
const _hoisted_3 = { id: "layoutSidenav_nav" }
const _hoisted_4 = { id: "layoutSidenav_content" }
const _hoisted_5 = { class: "container-fluid px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBarTop = _resolveComponent("NavBarTop")!
  const _component_NavBarLeft = _resolveComponent("NavBarLeft")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterAdmin = _resolveComponent("FooterAdmin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBarTop),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NavBarLeft)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("main", null, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_view)
          ])
        ]),
        _createVNode(_component_FooterAdmin)
      ])
    ])
  ]))
}