/**
   * @description Creamos un servicio para encapsular logia de llamadoa  las apis, para poder reutilizar esotos metodos
   */

// src/services/FixturesService.ts

import { ApiResponse } from '@/interfaces/ApiResponse';
import { Fixture } from '@/interfaces/Fixture';
import { UsuarioAdmin } from '@/interfaces/UsuarioAdmin';
import { TipoDeFixture } from '@/interfaces/TipoDeFixture';

const API_URL = 'http://localhost/fixtures';

const usuarioAlta: UsuarioAdmin = {
  id: 1,
  nombre: 'Admin',
  apellido: 'Admin',
  email: 'admin@example.com',
  fechaAlta: new Date(),
  fechaBaja: null, // Puedes dejarlo como null si no lo necesitas para mockear
  IdUsuarioAlta: 1,
  IdUsuarioModificacion: null, // Puedes dejarlo como null si no lo necesitas para mockear
  IdUsuarioBaja: null // Puedes dejarlo como null si no lo necesitas para mockear
};

// Mock de tipo de fixture
const tipoDeFixture: TipoDeFixture = {
  id: 1,
  nombre: 'Zonas y Eliminación Directa',
  fechaAlta: new Date(),
  fechaBaja: null,
  IdUsuarioAlta: 1,
  IdUsuarioModificacion: null,
  IdUsuarioBaja: null
};

// Variable global para almacenar los fixtures mockeados
const mockFixtures: Fixture[] = [
  new Fixture(
    1,
    'ACopa América 2024',
    new Date('2024-07-01'),
    new Date('2024-07-31'),
    100, // Valor por participante (mock)
    1,
    tipoDeFixture       
  ),
  new Fixture(
    3,
    'ZCopa Libertadores',
    new Date('2024-07-03'),
    new Date('2024-07-30'),
    150, // Valor por participante (mock)
    1,
    tipoDeFixture
  ),
  // Agregar más fixtures según sea necesario
];

class FixturesService {
  public async getAll(): Promise<ApiResponse<Fixture[]>> {
    try {
      // Simular una pequeña demora para emular una solicitud asíncrona
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Construir y devolver la ApiResponse mockeada
      return {
        result: mockFixtures,
        isSuccess: true,
        errores: []
      };
    } catch (error) {
      console.error('Error fetching fixtures:', error);
      return {
        result: [],
        isSuccess: false,
        errores: ['Unknown error']
      };
    }
  }

  public async getFixtureById(id: number): Promise<ApiResponse<Fixture>> {
    try {
      // Simular una pequeña demora para emular una solicitud asíncrona
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Buscar el fixture por ID
      const fixture = mockFixtures.find(fixture => fixture.id === id);
      if (!fixture) {
        throw new Error(`Fixture con ID ${id} no encontrado`);
      }

      // Construir y devolver la ApiResponse mockeada
      return {
        result: fixture,
        isSuccess: true,
        errores: []
      };
    } catch (error) {
      console.error('Error fetching fixture:', error);
      const defaultTipoDeFixture: TipoDeFixture = {
        id: 0,
        nombre: '',
        fechaAlta: new Date(),
        fechaBaja: null,
        IdUsuarioAlta: 0,
        IdUsuarioModificacion: null,
        IdUsuarioBaja: null
      };
      return {
        result: new Fixture(0, '', new Date(), new Date(), 0, 0, defaultTipoDeFixture),
        isSuccess: false,
        errores: ['Unknown error']
      };
    }
  }

  public async updateFixture(fixture: Fixture): Promise<ApiResponse<Fixture>> {
    try {
      // Simular una pequeña demora para emular una solicitud asíncrona
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Buscar el fixture por ID
      const index = mockFixtures.findIndex(f => f.id === fixture.id);
      if (index === -1) {
        throw new Error(`Fixture con ID ${fixture.id} no encontrado`);
      }

      // Actualizar el fixture en el array mockFixtures
      mockFixtures[index] = fixture;

      // Construir y devolver la ApiResponse mockeada
      return {
        result: fixture,
        isSuccess: true,
        errores: []
      };
    } catch (error) {
      console.error('Error updating fixture:', error);
      return {
        result: fixture,
        isSuccess: false,
        errores: ["Error al actualizar"]
      };
    }
  }
}

export default new FixturesService();



//El servicio a continuacion comentado es otra forma de armar el Servicio, sin crear una clase sino directamente exportando los metodos
// // FixturesService.ts

// import { ApiResponse } from '@/interfaces/ApiResponse';
// import { Fixture } from '@/interfaces/Fixture';
// import { UsuarioAdmin } from '@/interfaces/UsuarioAdmin';
// import { TipoDeFixture } from '@/interfaces/TipoDeFixture';

// const API_URL = 'http://localhost/fixtures';



// const usuarioAlta: UsuarioAdmin = {
//   id: 1,
//   nombre: 'Admin',
//   apellido: 'Admin',
//   email: 'admin@example.com',
//   fechaAlta: new Date(),
//   fechaBaja: null, // Puedes dejarlo como null si no lo necesitas para mockear
//   IdUsuarioAlta: 1,
//   IdUsuarioModificacion: null, // Puedes dejarlo como null si no lo necesitas para mockear
//   IdUsuarioBaja: null // Puedes dejarlo como null si no lo necesitas para mockear
// };

// // Mock de tipo de fixture
// const tipoDeFixture: TipoDeFixture = {
//   id: 1,
//   nombre: 'Zonas y Eliminación Directa',
//   fechaAlta: new Date(),
//   fechaBaja: null,
//   IdUsuarioAlta: 1,
//   IdUsuarioModificacion: null,
//   IdUsuarioBaja: null
// };

// // Variable global para almacenar los fixtures mockeados
// const mockFixtures: Fixture[] = [
//   new Fixture(
//     1,
//     'ACopa América 2024',
//     new Date('2024-07-01'),
//     new Date('2024-07-31'),
//     100, // Valor por participante (mock)
//     1,
//     tipoDeFixture       
//   ),
//   new Fixture(
//     3,
//     'ZCopa Libertadores',
//     new Date('2024-07-03'),
//     new Date('2024-07-30'),
//     150, // Valor por participante (mock)
//     1,
//     tipoDeFixture
//   ),
//   // Agregar más fixtures según sea necesario
// ];


// export const getAll = async (): Promise<ApiResponse<Fixture[]>> => {
//   try {
//     // Simular una pequeña demora para emular una solicitud asíncrona
//     await new Promise(resolve => setTimeout(resolve, 1000));

    
//     // Construir y devolver la ApiResponse mockeada
//     return {
//       result: mockFixtures,
//       isSuccess: true,
//       errores: []
//     };
//   } catch (error) {
//     console.error('Error fetching fixtures:', error);
//     throw error;
//   }
// };

// export const getFixtureById = async (id: number): Promise<ApiResponse<Fixture>> => {
//   try {
//     // Simular una pequeña demora para emular una solicitud asíncrona
//     await new Promise(resolve => setTimeout(resolve, 1000));
    
//     // Buscar el fixture por ID
//     const fixture = mockFixtures.find(fixture => fixture.id === id);
//     if (!fixture) {
//       throw new Error(`Fixture con ID ${id} no encontrado`);
//     }

//     // Construir y devolver la ApiResponse mockeada
//     return {
//       result: fixture,
//       isSuccess: true,
//       errores: []
//     };
//   } catch (error) {
//     console.error('Error fetching fixture:', error);
//     throw error;
//   }
// };
// export const updateFixture = async (fixture: Fixture): Promise<ApiResponse<Fixture>> => {
//   try {
//     // Simular una pequeña demora para emular una solicitud asíncrona
//     await new Promise(resolve => setTimeout(resolve, 1000));
    
//     // Buscar el fixture por ID
//     const index = mockFixtures.findIndex(f => f.id === fixture.id);
//     if (index === -1) {
//       throw new Error(`Fixture con ID ${fixture.id} no encontrado`);
//     }

//     // Actualizar el fixture en el array mockFixtures
//     mockFixtures[index] = fixture;

//     // Construir y devolver la ApiResponse mockeada
//     return {
//       result: fixture,
//       isSuccess: true,
//       errores: []
//     };
//   } catch (error) {
//     console.error('Error updating fixture:', error);
//     return {
//       result: fixture,
//       isSuccess: false,
//       errores: ["erro al actualizar"]
//     };
//   }
// };

