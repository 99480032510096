<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h1 class="text-primary">Fixtures</h1>
      <button @click="nuevoFixture" class="btn btn-success">Nuevo</button>
    </div>
    <p class="lead mb-4">Estos son los fixtures existentes en la plataforma</p>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Fecha Inicio</th>
            <th>Tipo de Fixture</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="fixture in paginatedFixtures" :key="fixture.id">
            <td v-info-toolTip="fixture.nombre">{{ fixture.id }}</td>
            <td>{{ fixture.nombre }}</td>
            <td>{{ formatFecha(fixture.fechaInicio)  }}</td>
            <td>{{ fixture.tipoDeFixture?.nombre }}</td>
            <td>              
              <button @click="eliminarFixture(fixture.id)" class="btn btn-sm btn-danger">
                <i class="bi bi-trash"></i>
              </button>
              <router-link :to="{ name: 'NuevoEditarFixture', params: { id: fixture.id.toString() } }" class="btn btn-sm btn-primary mr-2">
                <i class="bi bi-pencil"></i>
              </router-link>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="currentPage--" :disabled="currentPage === 1">Anterior</button>
        </li>
        <li class="page-item" :class="{ active: currentPage === page }" v-for="page in totalPages" :key="page">
          <button class="page-link" @click="currentPage = page">{{ page }}</button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="currentPage++" :disabled="currentPage === totalPages">Siguiente</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">

/**
 * <<<<<Ciclo de vida en vue>>>>>
 * 1. BeforeCreate (Antes de crear):
Se ejecuta antes de que se cree la instancia del componente.
Los datos aún no son reactivos.
No se puede acceder a la plantilla ni a los métodos del componente.
2. Created (Creado):
Se ejecuta después de crear la instancia del componente.
Los datos son reactivos.
Se puede acceder a la plantilla y a los métodos del componente.
Aún no se ha montado el componente en el DOM.
3. BeforeMount (Antes de montar):
Se ejecuta antes de montar la plantilla del componente en el DOM.
El componente ya está creado y sus datos son reactivos.
No se puede acceder al DOM.
4. Mounted (Montado):
Se ejecuta después de montar la plantilla del componente en el DOM.
El componente está completamente inicializado y listo para interactuar con el DOM.
Es un buen momento para realizar tareas como la obtención de datos o la configuración de eventos.
5. BeforeUpdate (Antes de actualizar):
Se ejecuta antes de que el componente se re-renderice debido a cambios en los datos.
El componente ya está montado y se puede acceder al DOM.
Es un buen momento para realizar tareas de preparación antes de la re-renderización.
6. Updated (Actualizado):
Se ejecuta después de que el componente se ha re-renderizado.
Los cambios en los datos se han reflejado en la interfaz de usuario.
Es un buen momento para realizar tareas posteriores a la re-renderización.
7. BeforeUnmount (Antes de desmontar):
Se ejecuta antes de que el componente se destruya y se elimine del DOM.
El componente ya no se puede interactuar con el DOM.
Es un buen momento para realizar tareas de limpieza, como eliminar escuchas de eventos.
8. Destroyed (Destruido):
Se ejecuta después de que el componente se ha destruido y se ha eliminado del DOM.
El componente ya no existe.
No se puede acceder a la instancia del componente ni a sus datos.
 */


import { ref, onMounted, computed } from 'vue';
import moment from 'moment';
import { Fixture } from '@/interfaces/Fixture';
import { useRouter } from 'vue-router';
import FixturesService from '@/services/FixturesService';


/**
   * @description Al utilizar ref, vue se queda observando los cambios en estas variables, entonces cuando su valor cambia cambia donde se este usando, por ejemplo
   * en la plantilla html o en una dato computed.  
   */
const fixtures = ref<Fixture[]>([]);
const currentPage = ref(1);
const pageSize = ref(10);

const router = useRouter();

/**
   * @description totalPages se define como computed, entonces vue re ejecutara la funcion que contiene cada vez que fixtures.value.length / pageSize.value
   * cambian. Estos dos valores deben ser datos reactivos (definidos con ref)
   */
const totalPages = computed(() => Math.ceil(fixtures.value.length / pageSize.value));

const paginatedFixtures = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return fixtures.value.slice(start, end);
});

const formatFecha = (fecha: Date): string => moment(fecha).format('DD/MM/YYYY');

const fetchFixtures = async () => {
  try {
    const response = await FixturesService.getAll();
    if (response.isSuccess) {
      fixtures.value = response.result;
    } else {
      console.error('Error fetching fixtures:', response.errores);
    }
  } catch (error) {
    console.error('Error fetching fixtures:', error);
  }
};


/**
   * @description vida que se llama después de que el componente se ha montado y está listo para interactuar con el DOM. 
   */
onMounted(fetchFixtures); 

const nuevoFixture = () => {
  // Implementar lógica para crear un nuevo fixture
  console.log('Crear nuevo fixture');
};

const eliminarFixture = (id: number) => {
  // Implementar lógica para eliminar un fixture
  console.log(`Eliminar fixture con ID: ${id}`);
};

</script>
<!--
  MUESTRO LA MISMA FUNCIONALIDAD DEL COMPONENTE PERO CON  defineComponent
  <script lang="ts">
// import { defineComponent, ref } from 'vue';
// import moment from 'moment';
// import { getAll } from '@/services/FixturesService';
// import { Fixture } from '@/interfaces/Fixture';
// import { useRouter } from 'vue-router';


// export default defineComponent({
//   name: 'FixturesPage',
//   data() {
//     return {
//       fixtures: [] as Fixture[],
//       currentPage: 1,
//       pageSize: 10,
//     };
//   },
//   computed: {
//     totalPages(): number {
//       return Math.ceil(this.fixtures.length / this.pageSize);
//     },
//     paginatedFixtures(): Fixture[] {
//       const start = (this.currentPage - 1) * this.pageSize;
//       const end = start + this.pageSize;
//       return this.fixtures.slice(start, end);
//     },
//   },
//   created() {
//     this.fetchFixtures(); // Llama a la función para obtener fixtures al crear el componente
//   },
//   methods: {
//     formatFecha(fecha: Date): string {
//       return moment(fecha).format('DD/MM/YYYY');
//     },
//     async fetchFixtures() {
//       try {
//         const response = await getAll(); // Llama a la función getAll del servicio FixturesService
//         if (response.isSuccess) {
//           this.fixtures = response.result; // Actualiza la lista de fixtures con los datos obtenidos
//         } else {
//           console.error('Error fetching fixtures:', response.errores);
//         }
//       } catch (error) {
//         console.error('Error fetching fixtures:', error);
//       }
//     },
//     nuevoFixture() {
//       // Implementar lógica para crear un nuevo fixture
//       console.log('Crear nuevo fixture');
//     },
//     // editarFixture(id: number) {      
//     //   var router = useRouter();      
//     //   router.push({ name: 'NuevoEditarFixture', params: { id: id.toString() } });
//     // },
//     eliminarFixture(id: number) {
//       // Implementar lógica para eliminar un fixture
//       console.log(`Eliminar fixture con ID: ${id}`);
//     },
//   },
// });
// </script>-->

<style scoped>


</style>
