<template>
  <div>
      <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
       
       <router-link class="navbar-brand ps-3" to="/">Fixtureando</router-link>
       
       <button
         class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
         id="sidebarToggle"
         href="#!"
       >
         <i class="fas fa-bars"></i>
       </button>
       
       <form
         class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"
       >
         <div class="input-group">
           <input
             class="form-control"
             type="text"
             placeholder="Buscar..."
             aria-label="Buscar for..."
             aria-describedby="btnNavbarSearch"
           />
           <button class="btn btn-primary" id="btnNavbarSearch" type="button">
             <i class="fas fa-search"></i>
           </button>
         </div>
       </form>
      
       <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
         <li class="nav-item dropdown">
           <a
             class="nav-link dropdown-toggle"
             id="navbarDropdown"
             href="#"
             role="button"
             data-bs-toggle="dropdown"
             aria-expanded="false"
           >
             <i class="fas fa-user fa-fw"></i>
           </a>
           <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
             <li><a class="dropdown-item" href="#!">Opciones</a></li>
             <li><a class="dropdown-item" href="#!">Activity Log</a></li>
             <li><hr class="dropdown-divider" /></li>
             <li><a class="dropdown-item" href="#!">Salir</a></li>
           </ul>
         </li>
       </ul>
     </nav>
    </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'NavBarTop'    
  });
  </script>


<style lang="css" scoped></style>