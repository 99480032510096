<template>
    <div>
      <h1>Panel</h1>
      <p>Bienvenido al panel de Fixtureando!</p>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'PanelPage'
  });
  </script>