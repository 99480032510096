import 'bootstrap-icons/font/bootstrap-icons.css';
import { createApp } from 'vue';
import App from './App.vue';

import { infoToolTip } from './directives/v-info-tooltip';


import router from './router/index';
/**
   * @description createApp(App<-----). App es el componente App.vue de la raiz del proyecto, este se carga en <div id="app"></div> del archivo index.html 
   */

const app = createApp(App);
app.directive('infoToolTip', infoToolTip);
app.use(router).mount('#app');