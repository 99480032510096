<template>
    <div class="sb-nav-fixed">
      <NavBarTop/>  
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <NavBarLeft/>
        </div>
        <div id="layoutSidenav_content">
          <main>
            <div class="container-fluid px-4">
              <router-view></router-view>
            </div>
          </main>
         <FooterAdmin/>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  /**
   * @description En router-view se cargará el componente que el rutero encuentre al elegir una ruta en el navbarleft
   */

   import { defineComponent } from 'vue';
   import FooterAdmin from '@/components/layout/FooterAdmin.vue';
   import NavBarTop from '@/components/layout/NavBarTop.vue';
   import NavBarLeft from '@/components/layout/NavBarLeft.vue';


  
  export default defineComponent({
    name: 'LayoutPrincipal',
    components: {
    FooterAdmin,
    NavBarTop,
    NavBarLeft
  },
    props: {
      msg: String
    }
  });
  </script>


<style scoped>

</style>
