/**
   * @description Esta directiva personalizada agrega un tooltip con información a cualqueir elemento del DOM
   * Se podrian crear, por ejemplo, directivas personalizadas para obligar al usuario que ingrese cierto formato en una cadena por ejemplo
   * o directiva para mostrar o no el elemento del doom si tiene o no permisos
   */

import { DirectiveBinding } from "vue";
interface HTMLElementWithTooltip extends HTMLElement {
  _destroyTooltip?: () => void;
}

/**
   * @description Esta funcion crea el elemento tooltip que se agregara al dom, se lo agrega oculto para luego al pasar el cursor por arriba de donde se este usando
   * la directiva, este se mostrara
   */
function createTooltip(el: HTMLElement, text: string) {
  const tooltip = document.createElement("div");
  tooltip.innerText = "Ayuda: " + text;
  tooltip.style.position = "absolute";
  tooltip.style.backgroundColor = "#fff";
  tooltip.style.border = "1px solid #ccc";
  tooltip.style.padding = "5px";
  tooltip.style.fontSize = "12px";
  tooltip.style.zIndex = "9999999999";
  tooltip.style.opacity = "1";
  tooltip.style.display = "none";

  document.body.appendChild(tooltip);
  function onMouseMove(e: MouseEvent) {
    tooltip.style.left = `${e.pageX + 20}px`;
    tooltip.style.top = `${e.pageY + 20}px`;
  }

  function onMouseEnter() {
    tooltip.style.display = "block";
    document.addEventListener("mousemove", onMouseMove);
  }

  function onMouseLeave() {
    tooltip.style.display = "none";
    document.removeEventListener("mousemove", onMouseMove);
  }

  el.addEventListener("mouseenter", onMouseEnter);
  el.addEventListener("mouseleave", onMouseLeave);

  return () => {
    el.removeEventListener("mouseenter", onMouseEnter);
    el.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("mousemove", onMouseMove);
    document.body.removeChild(tooltip);
  };
}

/**
   * @description Definicion de la directiva que luego se la invocara con v-info-toolTip
   */
export const infoToolTip = {
  mounted(el: HTMLElementWithTooltip, binding: DirectiveBinding) {
    const destroyTooltip = createTooltip(el, binding.value);
    el._destroyTooltip = destroyTooltip; // Store the destroy function on the element
  },
  unmounted(el: HTMLElementWithTooltip) {
    if (el._destroyTooltip) {
      el._destroyTooltip();
    }
  },
};
